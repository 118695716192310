(function() {
    'use strict';

    // Check to make sure service workers are supported in the current browser,
    // and that the current page is accessed from a secure origin. Using a
    // service worker from an insecure origin will trigger JS console errors. See
    // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
    let isLocalhost = Boolean(window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    if ('serviceWorker' in navigator &&
        (window.location.protocol === 'https:' || isLocalhost)) {
        navigator.serviceWorker.register('service-worker.js')
            .then(function(registration) {
                // updatefound is fired if service-worker.js changes.
                registration.onupdatefound = function() {
                    // updatefound is also fired the very first time the SW is installed,
                    // and there's no need to prompt for a reload at that point.
                    // So check here to see if the page is already controlled,
                    // i.e. whether there's an existing service worker.
                    if (navigator.serviceWorker.controller) {
                        // The updatefound event implies that registration.installing is set:
                        // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
                        let installingWorker = registration.installing;

                        installingWorker.onstatechange = function() {
                            switch (installingWorker.state) {
                                case 'installed':
                                    // At this point, the old content will have been purged and the
                                    // fresh content will have been added to the cache.
                                    // It's the perfect time to display a "New content is
                                    // available; please refresh." message in the page's interface.
                                    break;

                                case 'redundant':
                                    throw new Error('The installing ' +
                                        'service worker became redundant.');
                                default:
                                    // Ignore
                            }
                        };
                    }
                };
            }).catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
    }

    //Custom Javascript
    /**
     * Menu bar object
     * Handles all the handling for the menubar
     */
    class MenuBar {
        constructor() {
            //console.log('[MenuBar] object created...');

            //Selector initialization
            this.menuBar = document.querySelector('.menu');
            this.menuMain = document.querySelector('.menu__main');
            this.menuOptions = document.querySelector('.menu__options');

            //Functions calls
            this.onLoad = this.onLoad();
            this.showMenuOptions = this.showMenuOptions.bind(this);

            this.addEventListeners();
        }

        /**
         * addEventListener ()
         * Function to assign eventhandlers
         *
         * @return
         */
        addEventListeners() {
            //console.log('[MenuBar] Adding event listeners...');

            document.addEventListener('onload', this.onLoad);
            this.menuMain.addEventListener('click', this.showMenuOptions);
        }

        /**
         * onLoad ()
         * Function to handle evens happening during object load
         *
         * @return
         */
        onLoad() {
            //console.log('[MenuBar] onLoad...');

            //this.menuOptions.classList.add('hide');
        }

        /**
         * showMenuOptions ()
         * Function to toggle the menu opened and closed
         *
         * @return
         */
        showMenuOptions() {
            this.menuOptions.classList.toggle("hide");
        }
    }

    /**
     * Head object
     * Handles all the handling for the menubar
     */
    class Head {
        constructor() {
            //console.log('[Head] object created...');

            this.headSelection = document.querySelectorAll('.block');

            this.hoverHead = this.hoverHead.bind(this);

            this.target = null;

            this.addEventListeners();
        }

        addEventListeners() {
            document.addEventListener('click', this.hoverHead);
        }

        hoverHead(evt) {

            if (!evt.target.classList.contains('block'))
                return
            this.target = evt.target;
            evt.preventDefault();
        }
    }

    /**
     * RinoHead object
     */
    class RhinoHead extends Head {
        constructor() {
            super();
            //console.log('[RhinoHead] object created...');

            this.onLoad = this.onLoad();
            this.tilt = this.tilt.bind(this);

            this.addEventListeners();
        }

        addEventListeners() {
            document.addEventListener('onload', this.onLoad);
            document.addEventListener('onmouseover', this.tilt);
        }

        onLoad() {
            //console.log('[RhinoHead] onLoad()');
        }

        tilt(evt) {
            //console.log('[RhinoHead] tilt()');
        }
    }

    /**
     * DeerHead object
     */
    class DeerHead extends Head {
        constructor() {
            super();
            //console.log('[DeerHead] object created...');
            this.deerHead = document.querySelectorAll('.deerHead');

            this.onLoad = this.onLoad();
            this.grow = this.grow.bind(this);

            this.addEventListeners();
        }

        addEventListeners() {
            document.addEventListener('onload', this.onLoad);
            document.addEventListener('onmouseover', this.grow);
        }

        onLoad() {
            //console.log('[DeerHead] onLoad()');
        }

        grow(evt) {
            //console.log('[DeerHead] grow()');
        }
    }

    /**
     * BearHead object
     */
    class BearHead extends Head {
        constructor() {
            super();
            //console.log('[BearHead] object created...');
            this.bearHead = document.querySelectorAll('.bearHead');

            this.onLoad = this.onLoad();
            this.shake = this.shake.bind(this);

            this.addEventListeners();
        }

        addEventListeners() {
            document.addEventListener('onload', this.onLoad);
            document.addEventListener('onmouseover', this.shake);
        }

        onLoad() {
            //console.log('[BearHead] onLoad');
        }

        shake(evt) {
            //console.log('[BearHead] shake()');
        }
    }

    class Breaker {
        constructor() {
            //console.log('[Breaker] Creating...');
            this.breaker = document.querySelector('.breaker');
            this.breakerText = document.querySelectorAll('.breaker__text p');
            this.windowWidth = 0;

            this.addEventListeners();

            this.onLoad = this.onLoad();
            this.findWindowWidth = this.findWindowWidth();

            this.getWindowWidth();
        }

        getWindowWidth() {
            return this.windowWidth;
        }

        /**
         * addEventListeners ()
         *
         * Serves as a method to assign even listeners
         *
         * @return
         */
        addEventListeners() {
            document.addEventListener('onload', this.onLoad);
        }

        onLoad() {
            //console.log('[Breaker] onLoad()');
            this.windowWidth = this.findWindowWidth();
        }

        /**
         * Find the current width of the browser window
         *
         * @return monitorwidth;
         */
        findWindowWidth() {
            //console.log('[Breaker] findWidowWidth()');
            return Math.trunc(window.innerWidth || document.body.clientWidth || document.documentElement.clientWidth);
        }

        /**
         * overlapHandler (elem)
         *
         * Checks to see if the element is overlapping the text in the breaker section
         *
         * @return
         */
        isOverlap(elem, elemTitle) {
            //console.log('[Breaker] overlapHandler()');
            let breakerTextLength = this.breakerText.length;
            let topLimit = Math.trunc(elemTitle.getBoundingClientRect().top);
            let rightLimit = Math.trunc(elemTitle.getBoundingClientRect().right);
            let bottomLimit = Math.trunc(elemTitle.getBoundingClientRect().bottom);
            let leftLimit = Math.trunc(elemTitle.getBoundingClientRect().left);

            let elemTopLimit = Math.trunc(elem.getBoundingClientRect().top);
            let elemRightLimit = Math.trunc(elem.getBoundingClientRect().right);
            let elemBottomLimit = Math.trunc(elem.getBoundingClientRect().bottom);
            let elemLeftLimit = Math.trunc(elem.getBoundingClientRect().left);

            //Is the element overlapping?
            if (((bottomLimit + 100) <= elemBottomLimit && (topLimit) >= elemTopLimit + 150) ||
                ((leftLimit) <= elemLeftLimit + 200 && (rightLimit + 100) >= elemRightLimit)) {
                return 1; //There's an element overlapping
            } else {
                return 0; //Not overlapping
            }
        }
    }

    class BreakerParallax extends Breaker {
        constructor() {
            super();
            //console.log("[BreakerParallax] Creating...");
            this.parallaxSectionParent = document.querySelector('.breaker.parallax');
            this.parallaxSection = document.querySelector('.breaker__item.parallax');
            this.parallaxTextSection = document.querySelector('.breaker__text.parallax p');

            this.bgTranslateXPos = 0;
            this.mgTranslateXPos = 0;
            this.fgTranslateXPos = 0;

            if (this.parallaxSection) {
                this.placeItems = this.placeItems();
                setInterval(this.parallaxHandler = this.parallaxHandler(), 100);
            }
            this.addEventListeners();
        }

        addEventListeners() {
            document.addEventListener('onload', this.placeItems);
            window.addEventListener('scroll', this.parallaxHandler);
            //window.addEventListener('scroll', this.scrollHandler);
        }

        /**
         *  Place items on the parallax breaker section
         *
         * @return
         */
        placeItems() {
            //console.log("[BreakerParallax] placeItems()");

            /**
             * Extract the items and their properties to determine what properties they have
             */
            let bgExtract = document.querySelector('.breaker__item .breaker--parallax--background');
            let mgExtract = document.querySelector('.breaker__item .breaker--parallax--midground');
            let fgExtract = document.querySelector('.breaker__item .breaker--parallax--foreground');

            /**
             * Extract the file name for the layers
             */
            let bgImageSrc = bgExtract.src.split('/').pop();
            let mgImageSrc = mgExtract.src.split('/').pop();
            let fgImageSrc = fgExtract.src.split('/').pop();

            /**
             * Total space being placed
             */
            let totalSpace = 0;

            /**
             *  Initialize head elements
             */
            let fgElem = new Array();
            let mgElem = new Array();
            let bgElem = new Array();

            /**
             * Counter to keep track on how many elements of each are being placed
             */
            let fgCounter = 0;
            let mgCounter = 0;
            let bgCounter = 0;

            /**
             * Starting point variables for the elements
             */

            /**
             * Max amount of elements for each element
             */
            let bgMaxPlaced = Math.trunc(this.windowWidth / 175);
            let mgMaxPlaced = Math.trunc(this.windowWidth / 200);
            let fgMaxPlaced = Math.trunc(this.windowWidth / 350);


            /**
             * Foreground elements
             */
            for (let i = 0; i < fgMaxPlaced; i++) {
                fgElem[i] = document.createElement('img');
                fgElem[i].setAttribute('src', './assets/images/parallax/' + fgImageSrc);
                fgElem[i].setAttribute('aria-hidden', 'true');
                fgElem[i].className += "breaker--parallax--foreground";

                fgElem[i].style.left = (Math.floor((Math.random() * (this.windowWidth) - 100) + 1)) + 'px';
                fgElem[i].style.top = (Math.floor((Math.random() * 475) + 1)) + 'px';
                fgElem[i].style.transform = 'translateX(0px)';

                this.parallaxSection.appendChild(fgElem[i]);
                if (this.isOverlap(fgElem[i], this.parallaxTextSection)) {
                    fgElem[i].remove();
                }
            }

            /**
             * Midground elements
             */
            for (let i = 0; i < mgMaxPlaced; i++) {
                mgElem[i] = document.createElement('img');
                mgElem[i].setAttribute('src', './assets/images/parallax/' + mgImageSrc);
                mgElem[i].setAttribute('aria-hidden', 'true');
                mgElem[i].className += "breaker--parallax--midground";

                mgElem[i].style.left = (Math.floor((Math.random() * (this.windowWidth) - 100) + 1)) + 'px';
                mgElem[i].style.top = (Math.floor((Math.random() * 475) + 1)) + 'px';
                mgElem[i].style.transform = 'translateX(0px)';

                this.parallaxSection.appendChild(mgElem[i]);
                if (this.isOverlap(mgElem[i], this.parallaxTextSection)) {
                    mgElem[i].remove();
                }
            }

            /**
             * Background elements
             */
            for (let i = 0; i < bgMaxPlaced; i++) {
                bgElem[i] = document.createElement('img');
                bgElem[i].setAttribute('src', './assets/images/parallax/' + bgImageSrc);
                bgElem[i].setAttribute('aria-hidden', 'true');
                bgElem[i].className += "breaker--parallax--background";

                bgElem[i].style.left = (Math.floor((Math.random() * (this.windowWidth - 100)) + 1)) + 'px';
                bgElem[i].style.top = (Math.floor((Math.random() * 485) + 1)) + 'px';
                bgElem[i].style.transform = 'translateX(0px)';

                this.parallaxSection.appendChild(bgElem[i]);
                if (this.isOverlap(bgElem[i], this.parallaxTextSection)) {
                    bgElem[i].remove();
                }
            }
            bgExtract.remove();
            mgExtract.remove();
            fgExtract.remove();
        }

        /**
         * parallaxHandler ()
         *
         * Handles the parallax sections and how it functions
         *
         * @return
         */
        parallaxHandler() {
            //console.log('[BreakerParallax] parallaxHandler()');
            let topOffset = window.pageYOffset | document.body.scrollTop;
            let bgItems = document.querySelectorAll('.breaker--parallax--background');
            let mgItems = document.querySelectorAll('.breaker--parallax--midground');
            let fgItems = document.querySelectorAll('.breaker--parallax--foreground');

            let breakerTopOffset = document.querySelector('.breaker__item.parallax').getBoundingClientRect().top;

            let bgItemsLength = bgItems.length;
            let mgItemsLength = mgItems.length;
            let fgItemsLength = fgItems.length;

            /**
             *  Parallax config options
             *
             */
            const SPEED = 100;

            //TODO: MOVE OUT SOMEWHERE ELSE TO IMPROVE PERFORMANCE
            let windowLimit = window.innerWidth;

            //Handles animations when scrolling down
            if (topOffset >= breakerTopOffset - (topOffset * 2)) {

                /**
                 * Translate background movement
                 */
                for (let i = 0; i < bgItemsLength; i++) {
                    let leftVal = Math.trunc(bgItems[i].getBoundingClientRect().left);

                    let t = bgItems[i].style.transform;

                    /**
                     * Get translateX value (in px)
                     */
                    let zX = t.match(/translateX\(([0-9]+(px|em|%|ex|ch|rem|vh|vw|vmin|vmax|mm|cm|in|pt|pc))\)/);
                    zX = zX ? parseInt(zX[1]) : '0';
                    zX = zX + 3;

                    bgItems[i].style.transform = 'translateX(' + zX + 'px)';

                    if ((parseInt(bgItems[i].style.left) + zX) >= (windowLimit)) {
                        bgItems[i].style.left = '-100px';
                        bgItems[i].style.transform = 'translateX(0px)';
                    }
                }

                /**
                 * Translate midground movement
                 */
                for (let i = 0; i < mgItemsLength; i++) {
                    let leftVal = Math.trunc(mgItems[i].getBoundingClientRect().left);

                    let t = mgItems[i].style.transform;

                    /**
                     * Get translateX value (in px)
                     */
                    let zX = t.match(/translateX\(([0-9]+(px|em|%|ex|ch|rem|vh|vw|vmin|vmax|mm|cm|in|pt|pc))\)/);
                    zX = zX ? parseInt(zX[1]) : '0';
                    zX = zX + 2;

                    mgItems[i].style.transform = 'translateX(' + zX + 'px)';

                    if ((parseInt(mgItems[i].style.left) + zX) >= (windowLimit)) {
                        mgItems[i].style.left = '-100px';
                        mgItems[i].style.transform = 'translateX(0px)';
                    }
                }

                /**
                 * Translate foreground movement
                 */
                for (let i = 0; i < fgItemsLength; i++) {
                    let leftVal = Math.trunc(fgItems[i].getBoundingClientRect().left);

                    let t = fgItems[i].style.transform;

                    /**
                     * Get translateX value (in px)
                     */
                    let zX = t.match(/translateX\(([0-9]+(px|em|%|ex|ch|rem|vh|vw|vmin|vmax|mm|cm|in|pt|pc))\)/);
                    zX = zX ? parseInt(zX[1]) : '0';
                    zX = zX + 1;

                    fgItems[i].style.transform = 'translateX(' + zX + 'px)';

                    if ((parseInt(fgItems[i].style.left) + zX) >= (windowLimit)) {
                        fgItems[i].style.left = '-100px';
                        fgItems[i].style.transform = 'translateX(0px)';
                    }
                }
            }
        }
    }

    class BreakerHeads extends Breaker {
        constructor() {
            super();
            //console.log("[BreakerHeads] Creating...");

            this.headRhino = document.querySelectorAll('.breaker--tilt');
            this.headBear = document.querySelectorAll('.breaker--shake');
            this.headDeer = document.querySelectorAll('.breaker--pop');
            this.headsSection = document.querySelector('.breaker__item.heads'); //Selects the heads section
            this.headsTextSection = document.querySelector('.breaker__text.heads p');

            this.HEAD_LEFT_GAP = 200; //Space between the most left side of the image to the next image
            this.HEAD_GAP = 75; //The amount of space between each head element
            this.HEAD_WIDTH = 125; //Width of the head image
            this.HEAD_ROW = 3; //Amount of rows in the breaker section

            /**
             *  Top position of row to place the head
             */
            this.HEAD_ROW_ONE_HEIGHT = -125; //Top position of the heads in row one
            this.HEAD_ROW_TWO_HEIGHT = 150; //Top position of the heads in row two
            this.HEAD_ROW_THREE_HEIGHT = 425; //Top position of the heads in row three

            if (this.headsSection) {
                this.loadHeads = this.loadHeads();
            }

            this.addEventListeners();
        }

        addEventListeners() {
            document.addEventListener('onload', this.loadHeads);
        }

        loadHeads() {
            //console.log('[BreakerHeads] loadHeads()');
            /**
             * For loop that places the head images on the breaker section
             * const HEAD_ROW determines how many rows of heads there should be\
             */
            for (var i = 0; i < this.HEAD_ROW; i++) {
                this.setHead(i);
            }
        }

        /**
         * function setHead(rowNum)
         * rowNum = the current row it's looping through.
         *
         * @return null;
         *
         * @TODO Should the other fo rloop be placed in here? Pass in the HEAD_ROW value instead?
         */
        setHead(rowNum) {
            /**
             *  Initialize head elements
             */
            var rhinoElem = new Array();
            var deerElem = new Array();
            var bearElem = new Array();

            /**
             *  Variable Initialization
             */
            var rowHeight = 0; //Placement of the current row height
            var totalSpace = (rowNum === 1) ? this.HEAD_GAP : 0; //Determines if it's on row 2 so to add the extra head gap at the beginning
            var headCounter = 0; //amount of heads can fit in a row counter. FOR DEBUG PURPOSES

            /**
             *
             */
            for (var i = 0; i < 100; i++) {
                /**
                 * Check what row the loop is currently on to determine where it belongs in relation
                 * to the top
                 */
                switch (rowNum) {
                    case 0:
                        rowHeight = this.HEAD_ROW_ONE_HEIGHT;
                        break;
                    case 1:
                        rowHeight = this.HEAD_ROW_TWO_HEIGHT;
                        break;
                    case 2:
                        rowHeight = this.HEAD_ROW_THREE_HEIGHT;
                        break;
                }
                rhinoElem[i] = document.createElement('img');
                rhinoElem[i].setAttribute('src', './assets/images/heads/rhino_head.png');
                rhinoElem[i].setAttribute('alt', 'rhino head');
                rhinoElem[i].setAttribute('aria-hidden', 'true');
                rhinoElem[i].className += "breaker--tilt";

                rhinoElem[i].style.left = totalSpace + 'px';
                rhinoElem[i].style.top = rowHeight + 'px';
                rhinoElem[i].style.left = totalSpace + 'px';

                deerElem[i] = document.createElement('img');
                deerElem[i].setAttribute('src', './assets/images/heads/deer_head.png');
                deerElem[i].setAttribute('alt', 'deer head');
                deerElem[i].setAttribute('aria-hidden', 'true');
                deerElem[i].className += "breaker--pop";

                deerElem[i].style.left = totalSpace + 'px';
                deerElem[i].style.top = rowHeight + 'px';
                deerElem[i].style.left = totalSpace + 'px';

                bearElem[i] = document.createElement('img');
                bearElem[i].setAttribute('src', './assets/images/heads/bear_head.png');
                bearElem[i].setAttribute('alt', 'bear head');
                bearElem[i].setAttribute('aria-hidden', 'true');
                bearElem[i].className += "breaker--shake";

                bearElem[i].style.left = totalSpace + 'px';
                bearElem[i].style.top = rowHeight + 'px';
                bearElem[i].style.left = totalSpace + 'px';

                /*
                 * Determines what head should be places using the equation i%3
                 * 1 = rhino head
                 * 2 = deer head
                 * 3 = bear head
                 */
                var headChoice = Math.trunc(i % 3);
                switch (headChoice) {
                    case 0:
                        (rowNum !== 1) ? this.headsSection.appendChild(bearElem[i]): this.headsSection.appendChild(rhinoElem[i]);
                        break;
                    case 1:
                        //console.log('Inputting rhino head...');
                        (rowNum !== 1) ? this.headsSection.appendChild(rhinoElem[i]): this.headsSection.appendChild(deerElem[i]);
                        break;
                    case 2:
                        //console.log('Inputting deer head...');
                        (rowNum !== 1) ? this.headsSection.appendChild(deerElem[i]): this.headsSection.appendChild(bearElem[i]);
                        break;
                }

                if (rowNum === 1) {
                    if (this.isOverlap(deerElem[i], this.headsTextSection)) {
                        //console.log("DANGER OVERLAPPING!!!!");
                        deerElem[i].remove();
                    }
                    if (this.isOverlap(bearElem[i], this.headsTextSection)) {
                        //console.log("DANGER OVERLAPPING!!!!");
                        bearElem[i].remove();
                    }
                    if (this.isOverlap(rhinoElem[i], this.headsTextSection)) {
                        //console.log("DANGER OVERLAPPING!!!!");
                        rhinoElem[i].remove();
                    }
                }

                //Allows for the position of the head to be moved left
                //totalSpace holds the current value of the values
                totalSpace += this.HEAD_GAP;
                totalSpace += this.HEAD_WIDTH;
                headCounter++;

                //Check if totalSpace is surpassing the window width
                //TRUE -> Stop placement of head, reset totalSpace, break out of loop
                if ((totalSpace + 150) >= this.windowWidth) {
                    totalSpace = 0;
                    break;
                }
            }
        }
    }

    /**
     * Creating new objects
     */
    let breakerParallax = document.querySelector('.breaker .parallax');
    let breakerHeads = document.querySelector('.breaker .heads');

    new MenuBar();
    if (breakerHeads) {
        new BreakerHeads();
    }
    if (breakerParallax) {
        new BreakerParallax();
    }
})();